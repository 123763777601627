import moment from 'moment';
import {
  GroupbyList,
  DisplayList,
  ContentList,
  SourceList
} from '../../Functional/Strings';
import { atom } from 'recoil';

export const initalFilterState = {
  start_date: moment()
    .subtract(14, 'days')
    .calendar(),
  end_date: moment().format('L'),
  display_array: DisplayList,
  groupby_array: GroupbyList,
  content_array: ContentList,
  source_array: SourceList,
  display_value: DisplayList[0],
  group_by_value: GroupbyList[0],
  content_value: ContentList[0],
  space_value: [],
  source_value: SourceList[0],
  save_flag: false
};

const FilterAtom = atom({
  default: initalFilterState,
  key: 'FILTER_ATOM'
});

export default FilterAtom;
